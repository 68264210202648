import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../Reducers/authSlice";
import { CiUser } from "react-icons/ci";
import { FaCartFlatbedSuitcase, FaLanguage } from "react-icons/fa6";
import { GrCurrency } from "react-icons/gr";
import CurrencyMenu from "./CurrencyMenu";
import LanguageMenu from "./LanguageMenu";
import { setCurrency } from "../Reducers/authSlice";

const HeaderComponent = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const loginData = useSelector((state) => state.authData);
  const cartlist = useSelector((state) => state.cartData.data);

  const handleloginbtn = (id) => {
    if (id === "logout-user") {
      dispatch(logout());
      navigate("/");
      return true;
    }
    if (id === "login-user") {
      navigate("/login-user");
    }
  };

  const handleCartNavigation = () => {
    if (cartlist.length > 0) {
      navigate("/cart-details");
    } else {
      window.alert("No Items In Cart");
    }
  };

  const dispatch = useDispatch();
  const currentCurrency = useSelector((state) => state.authData);
  const currencyContent = [
    {
      id: 1,
      name: "United Arab Emirates",
      currency: "AED",
      symbol: "د.إ",
      image: "united-arab-emirates.png",
    },
    {
      id: 2,
      name: "United States ",
      currency: "USD",
      symbol: "$",
      image: "united-states.png",
    },
    {
      id: 3,
      name: "India Rupee",
      currency: "INR",
      symbol: "₹",
      image: "india.png",
    },
    {
      id: 4,
      name: "United Kingdom",
      currency: "GBP",
      symbol: "£",
      image: "united-kingdom.png",
    },
    {
      id: 5,
      name: "Euro",
      currency: "EUR",
      symbol: "€",
      image: "european.png",
    },
  ];

  const changeCurrency = (currency) => {
    dispatch(setCurrency(currency));
  };

  return (
    <Fragment>
      {/* Topbar Start */}
      <div className="container-fluid topbar  d-none d-xl-block w-100 bg-[#00646f]">
        <div className="container">
          <div className="row gx-0 align-items-center" style={{ height: 45 }}>
            <div className="col-lg-6 text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
                <Link href="#" className="text-muted me-4">
                  <i className="fas fa-map-marker-alt text-primary me-2 text-white" />
                  Find A Location
                </Link>
                <Link href="tel:+91 93277 53873" className="text-muted me-4">
                  <i className="fas fa-phone-alt text-primary me-2 text-white" />
                  +91 7567992575
                </Link>
                <Link
                  href="mailto:example@gmail.com"
                  className="text-muted me-0"
                >
                  <i className="fas fa-envelope text-primary me-2 text-white" />
                  info@parmartours.com
                </Link>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-end">
              <div className="d-flex align-items-center justify-content-end">
                <Link
                  href="#"
                  className="btn btn-light btn-sm-square rounded-circle me-3"
                >
                  <i className="fab fa-facebook-f" />
                </Link>
                <Link
                  href="#"
                  className="btn btn-light btn-sm-square rounded-circle me-3"
                >
                  <i className="fab fa-twitter" />
                </Link>
                <Link
                  href="#"
                  className="btn btn-light btn-sm-square rounded-circle me-3"
                >
                  <i className="fab fa-instagram" />
                </Link>
                <Link
                  href="#"
                  className="btn btn-light btn-sm-square rounded-circle me-0"
                >
                  <i className="fab fa-linkedin-in" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar & Hero Start */}
      <div className="container-fluid nav-bar sticky-top px-0 px-lg-4 py-2 py-lg-0">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to="/" className="navbar-brand p-0">
              <img src="/img/logo.png" alt="Logo" />
            </Link>

            {/* Cart Icon - Display only on mobile */}
            <div className="mr-5 mt-[7px] block md:hidden">
              <div onClick={() => handleCartNavigation()}>
                <div className="relative flex items-center cursor-pointer">
                  <FaCartFlatbedSuitcase color="#01b8cc" size={25} />
                  <span
                    className={`${
                      cartlist.length > 99 ? "w-7 h-7 " : "w-5 h-5"
                    } absolute flex items-center justify-center text-sm text-white bg-orange-600 rounded-full left-3 -top-3`}
                  >
                    {cartlist.length}
                  </span>
                </div>
              </div>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="fa fa-bars" />
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-auto py-0">
                <Link
                  to="/"
                  className={`nav-item nav-link ${
                    pathname === "/" && "active"
                  }`}
                >
                  Home
                </Link>
                <Link
                  to="about-us"
                  className={`nav-item nav-link ${
                    pathname === "/about-us" && "active"
                  }`}
                >
                  About
                </Link>
                <Link
                  to="attraction-list"
                  className={`nav-item nav-link ${
                    pathname === "/attraction-list" && "active"
                  }`}
                >
                  Attractions
                </Link>
                <Link
                  to="combopack-list"
                  className={`nav-item nav-link ${
                    pathname === "/combopack-list" && "active"
                  }`}
                >
                  Combo Packs
                </Link>
                <Link
                  to="tourpackages-list"
                  className={`nav-item nav-link ${
                    pathname === "/tourpackages-list" && "active"
                  }`}
                >
                  Holiday Pack
                </Link>
                <Link
                  to="contact-us"
                  className={`nav-item nav-link ${
                    pathname === "/contact-us" && "active"
                  }`}
                >
                  Contact
                </Link>

                <div className="nav-item  nav-link md:hidden dropdown mb-5">
                  <a data-bs-toggle="dropdown" aria-expanded="false">
                    Currency
                  </a>
                  <div className="dropdown-menu m-0">
                    {/* Currency Options */}
                    <div className="flex flex-wrap gap-3 p-2">
                      {currencyContent.map((item, index) => (
                        <div
                          className="hover:bg-[#ffc107] hover:text-white cursor-pointer p-1 rounded"
                          key={index}
                          onClick={() => changeCurrency(item.currency)}
                        >
                          {item.symbol} - {item.currency}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {loginData?.isLoggedIn === false ? (
                  <button
                    onClick={() => handleloginbtn("login-user")}
                    className="btn btn-primary  md:hidden rounded-pill py-2 px-4"
                  >
                    Login
                  </button>
                ) : (
                  <div className="nav-item  md:hidden dropdown">
                    <button
                      className="btn btn-primary rounded-full py-2 px-4 flex items-center gap-2 dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Profile <CiUser />
                    </button>
                    <div className="dropdown-menu m-0">
                      <Link
                        to="/user-booking-details"
                        className="dropdown-item"
                      >
                        My Bookings
                      </Link>
                      <a
                        className="dropdown-item"
                        onClick={() => handleloginbtn("logout-user")}
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {/* Currency and Language Menus */}
              <div className="mr-5 mt-[7px] hidden md:block">
                <LanguageMenu />
              </div>
              <div className="mr-5 mt-[7px] hidden md:block">
                <CurrencyMenu />
              </div>

              {/* Cart Icon - Hidden in larger viewports */}
              <div className="mr-5 mt-[7px] hidden md:block">
                <div onClick={() => handleCartNavigation()}>
                  <div className="relative flex items-center cursor-pointer">
                    <FaCartFlatbedSuitcase color="#01b8cc" size={25} />
                    <span
                      className={`${
                        cartlist.length > 99 ? "w-7 h-7 " : "w-5 h-5"
                      } absolute flex items-center justify-center text-sm text-white bg-orange-600 rounded-full left-3 -top-3`}
                    >
                      {cartlist.length}
                    </span>
                  </div>
                </div>
              </div>

              {/* Profile/Login Button */}
              {loginData?.isLoggedIn === false ? (
                <button
                  onClick={() => handleloginbtn("login-user")}
                  className="btn btn-primary hidden md:block rounded-pill py-2 px-4"
                >
                  Login
                </button>
              ) : (
                <div className="nav-item hidden md:block dropdown">
                  <button className="btn btn-primary rounded-full py-2 px-4 flex items-center gap-2">
                    Profile <CiUser />
                  </button>
                  <div className="dropdown-menu m-0">
                    <Link to="/user-booking-details" className="dropdown-item">
                      My Bookings
                    </Link>
                    <a
                      className="dropdown-item"
                      onClick={() => handleloginbtn("logout-user")}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
      {/* Navbar & Hero End */}
    </Fragment>
  );
};

export default HeaderComponent;
